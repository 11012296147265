import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import WarningIcon from '@material-ui/icons/Warning';
// import PeopleIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import CommuteIcon from '@material-ui/icons/Commute';
import HomeIcon from '@material-ui/icons/Home';
import { history } from '../../helpers/history';


class ListItems extends React.Component {

    render() {
        const { t, match } = this.props
        return (
            <div>
                <ListItem button onClick={() => {
                    history.push(`home`)
                    this.props.resetData();
                    window.location.reload()
                }}
                    component={Link} to={`home`} style={match.path.includes('home') ? { backgroundColor: '#bfe4f4' } : {}}>
                    <ListItemIcon>
                        <HomeIcon color={'secondary'} />
                    </ListItemIcon>
                    <ListItemText
                        style={match?.path.includes('home') ? { color: "black" } : { color: "white" }}
                        primary={t('dashboard.items.home')}
                    />
                </ListItem>

                <ListItem button component={Link} to={`/profile`} style={match.path.includes('profile') ? { backgroundColor: '#bfe4f4' } : {}}>
                    <ListItemIcon>
                        <PersonIcon color={'secondary'} />
                    </ListItemIcon>
                    <ListItemText
                        style={match?.path.includes('profile') ? { color: "black" } : { color: "white" }}
                        primary={t('dashboard.items.profile')}
                    />
                </ListItem>

                <ListItem button component={Link} to={`bookings`} style={match.path.includes('bookings') ? { backgroundColor: '#bfe4f4' } : {}}>
                    <ListItemIcon>
                        <CommuteIcon color={'secondary'} />
                    </ListItemIcon>
                    <ListItemText
                        style={match?.path.includes('bookings') ? { color: "black" } : { color: "white" }}
                        primary={t('dashboard.items.bookings')}
                    />
                </ListItem>

                {/* <ListItem button component={Link} to={`/routes`} >
                    <ListItemIcon>
                        <PeopleIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText style={{ color: 'white' }} primary={t('dashboard.items.routes')} />
                </ListItem>

                <ListItem button component={Link} to={`/incidences`}>
                    <ListItemIcon>
                        <WarningIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText style={{ color: 'white' }} primary={t('dashboard.items.incidents')} />
                </ListItem> */}
            </div>
        );
    }
}


export default ListItems