import { Lens } from '@material-ui/icons';
import React from 'react';
import MaterialTableExtended from '../../material-table'
import moment from 'moment';
import { green } from '@material-ui/core/colors';

class ListAbsencesAndPenalties extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { t, customerReducer } = this.props
        const translations = t('material_table.tableStrings', { returnObjects: true })

        return (
            <React.Fragment>
                <MaterialTableExtended
                    title={t('profile.shared.absencesAndPenalties.title')}
                    localization={translations}
                    pageSize={5}
                    actionsColumnIndex={5}
                    columns={[
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.service'),
                            field: 'serviceId',
                            render: rowData => rowData.isInServicePenalty ?
                                <div style={{ flexDirection: 'row' }}>
                                    <Lens color="error" style={{ fontSize: 15, marginRight: 5 }} />
                                    <label>{rowData.serviceName}</label>
                                </div>
                                :
                                <div style={{ flexDirection: 'row' }}>
                                    <Lens style={{ fontSize: 15, color: green[500], marginRight: 5 }} />
                                    <label>{rowData.serviceName}</label>
                                </div>
                        },
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.maxAbsences'),
                            field: 'maxAbsences'
                        },
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.absences'),
                            field: 'absences'
                        },
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.penalty'),
                            field: 'isInServicePenalty',
                            render: rowData => rowData.isInServicePenalty
                                ? <label>{t('profile.shared.absencesAndPenalties.yes')}</label>
                                : <label>{t('profile.shared.absencesAndPenalties.no')}</label>
                        },
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.penaltyEndDate'),
                            field: 'isInServicePenalty',
                            render: rowData => rowData.isInServicePenalty
                                ? <label>{moment(rowData.currentPenaltyEndDate).local().format('DD/MM/HH HH:MM')}</label>
                                : <label>-</label>
                        },
                        {
                            title: t('profile.shared.absencesAndPenalties.columns.totalPenalties'),
                            render: rowData => <label>{rowData.penalties.length || 0}</label>
                        },
                    ]}
                    data={customerReducer.currentUser.absences || []}
                />
            </React.Fragment>
        );
    }
}

export default ListAbsencesAndPenalties;