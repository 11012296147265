import { Button, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import PersonalData from './shared/personalData';
import ListRelatedCustomers from './shared/listRelatedCustomer';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import AlertDialog from '../alert-dialog';
import Spinner from '../shared/spinner';
import ListAbsencesAndPenalties from './shared/listAbsencesAndPenalties';
import stringConstants from '../../constants/strings';
import { GoogleApiWrapper } from 'google-maps-react';
import ItemAddress from './shared/itemAddress'
import { getLocation } from '../../helpers/google';
import utils from '../../helpers/validations';
import AddIcon from "@material-ui/icons/AddBox";
import { IconButton, Tooltip } from "@material-ui/core";
//import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'red'
    },
}));
class ProfileCustomerView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openModalForm: false,
            name: props.name || '',
            surname1: props.surname1 || '',
            surname2: props.surname2 || '',
            email: props.email || '',
            document: props.document || '',
            phone: props.phone || '',
            relatedCustomers: props.relatedCustomers || [],
            prevFavouriteAddresses: props.customerReducer?.currentUser?.favouriteAddresses
            //props.customerReducer.currentUser.favouriteAddresses && props.customerReducer.currentUser.favouriteAddresses?.length > 0
                ? props.customerReducer?.currentUser?.favouriteAddresses
                : [],
            favouriteAddresses: props.customerReducer.currentUser?.favouriteAddresses && props.customerReducer.currentUser.favouriteAddresses?.length > 0 ? props.customerReducer.currentUser.favouriteAddresses : [
                // {
                //     addressPosition: {
                //         lat: 0,
                //         lon: 0,
                //         address: ""
                //     },
                //     type: ''
                // }
            ] ?? [],
            errors: {
                favouriteAddresses: [
                    // {
                    //     address: { result: true, message: "" },
                    //     type: { result: true, message: "" },
                    // },
                ],
                name: { 'result': false, 'message': '' },
                surname1: { 'result': false, 'message': '' },
                surname2: { 'result': false, 'message': '' },
                email: { 'result': false, 'message': '' },
                document: { 'result': false, 'message': '' },
                phone: { 'result': false, 'message': '' }
            },
            dialog: false,
            readOnly: true
        }
        this.initializeStateErrors();
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.saveRelatedCustomer = this.saveRelatedCustomer.bind(this)

        this.addTime = this.addTime.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.validateFavouriteAddresses = this.validateFavouriteAddresses.bind(this)
    }

    componentDidMount() {
        this.props.customerAddresses()
        this.props.getCustomerProfile()
    }

    initializeStateErrors = () => {
        this.state.favouriteAddresses.forEach(element => {
            this.state.errors.favouriteAddresses.push({
                address: { result: true, message: "" },
                type: { result: true, message: "" },
            })
        });
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.customerReducer.relatedCustomers != prevProps.customerReducer.relatedCustomers) {
    //         console.log("here, relatedCustomers ha canviat!");
    //         console.log("prevProps.customerReducer.relatedCustomers => ", prevProps.customerReducer.relatedCustomers);
    //         console.log("this.props.customerReducer.relatedCustomer => ", this.props.customerReducer.relatedCustomers)
    //         console.log("prevState.relatedCustomers => ", prevState.relatedCustomers)
    //         console.log("this.state.relatedCustomers => ", this.state.relatedCustomers)
    //     }
    // }

    saveRelatedCustomer(relatedCustomer) {
        this.setState({
            relatedCustomers: [...this.state.relatedCustomers, relatedCustomer]
        })
    }

    openModal() {
        this.setState({ openModalForm: true })
    }

    closeModal() {
        this.setState({ openModalForm: false })
    }

    toggleDiaolog = () => {
        this.setState({ dialog: !this.state.dialog })
    }

    getPersonalInfo = () => {
        this.props.getPersonalInfo();
    }

    addTime() {
        let errors = this.state.errors;
        errors.favouriteAddresses.push({
            type: { result: false, message: "" },
            address: { result: false, message: "" },
        });
        this.setState({
            favouriteAddresses: [
                ...this.state.favouriteAddresses,
                {
                    addressPosition: {
                        lat: 0,
                        lon: 0,
                        address: ""
                    },
                    type: ''
                },
            ],
            errors,
        });

    }

    removeItem(index) {
        let errors = this.state.errors;
        errors.favouriteAddresses = errors.favouriteAddresses.filter((_, i) => i !== index);
        this.setState({
            favouriteAddresses: this.state.favouriteAddresses.filter((_, i) => i !== index),
            errors,
        });
    }

    onChange(event) {
        let { name, value } = event.target;
        let favouriteAddresses = this.state.favouriteAddresses;
        let errors = this.state.errors;
        let array = name.split('_')
        favouriteAddresses[array[1]][array[0]] = value
        errors.favouriteAddresses[array[1]][array[0]].message = ""
        this.setState({ favouriteAddresses })
    }

    onChangeAddress = (newValue, event) => {
        const array = event.nativeEvent.currentTarget.activeElement.name.split('_')
        let index = array[1]
        let favouriteAddresses = this.state.favouriteAddresses;
        favouriteAddresses[index]["addressPosition"]["address"] = ""
        favouriteAddresses[index]["addressPosition"].lat = ""
        favouriteAddresses[index]["addressPosition"].lon = ""
        let errors = this.state.errors
        if (newValue) {
            getLocation(newValue.description).then(
                location => {
                    favouriteAddresses[index]["addressPosition"]["address"] = newValue.description
                    favouriteAddresses[index]["addressPosition"].lat = location.lat
                    favouriteAddresses[index]["addressPosition"].lon = location.lng
                    this.setState({
                        favouriteAddresses
                    }, () => {
                        this.validateFavouriteAddresses()
                    })
                }
            )
        }
        else {
            favouriteAddresses[index]["addressPosition"]["address"] = ""
            favouriteAddresses[index]["addressPosition"].lat = ""
            favouriteAddresses[index]["addressPosition"].lon = ""
            this.setState({
                favouriteAddresses
            }, () => {
                this.validateFavouriteAddresses()
            })
        }
    }

    validateFavouriteAddresses() {
        let state = this.state
        let errors = this.state.errors
        this.state.errors.favouriteAddresses.forEach(function (element, i) {
            if (state.favouriteAddresses[i].addressPosition.address != '' && state.favouriteAddresses[i].type == "") {
                errors.favouriteAddresses[i].type = utils.required(state.favouriteAddresses[i].type)
            } else {
                errors.favouriteAddresses[i].type = { result: true, message: "" }
            }
            if (state.favouriteAddresses[i].addressPosition.address == '' && state.favouriteAddresses[i].type != "") {
                errors.favouriteAddresses[i].address = utils.required(state.favouriteAddresses[i].addressPosition.address)
            } else {
                errors.favouriteAddresses[i].address = { result: true, message: "" }
            }
            if (state.favouriteAddresses[i].addressPosition.address == '' && state.favouriteAddresses[i].type == "") {
                errors.favouriteAddresses[i].type = utils.required(state.favouriteAddresses[i].type)
                errors.favouriteAddresses[i].address = utils.required(state.favouriteAddresses[i].addressPosition.address)
            }
            // else {
            //     errors.favouriteAddresses[i].address = { result: true, message: "" };
            //     errors.favouriteAddresses[i].type = { result: true, message: "" };
            // }
        });
        this.setState({ errors });
    }

    render() {
        const { t, customerReducer } = this.props;
        return (
            <React.Fragment>
                {!customerReducer.pending && customerReducer.currentUser != null ?
                    <React.Fragment>
                        <PersonalData
                            {...this.props}
                            user={this.props.customerReducer.currentUser}
                            withAction={true}
                            saveCustomer={true}
                            favouriteAddresses={this.state.favouriteAddresses}
                            errorsFavouriteAddresses={this.state.errors.favouriteAddresses}
                            closeModal={this.closeModal}
                            validateFavouriteAddresses={() => this.validateFavouriteAddresses()}
                        //title={t(('dashboard.menu.profile'))}
                        />
                        <Grid container spacing={3}>

                            {/*  */}
                            <Grid item md={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={11}>
                                <p style={{ fontSize: 18, paddingLeft: 20 }}>
                                    {t('profile.shared.addresses.title')}
                                    {this.state.favouriteAddresses == null || this.state.favouriteAddresses?.length == 0 && (
                                        <IconButton onClick={() => this.addTime()}>
                                            <AddIcon />
                                        </IconButton>
                                    )}
                                </p>
                            </Grid>
                            {this.state.favouriteAddresses.map((item, index) => (
                                <ItemAddress
                                    key={index}
                                    readOnly={this.props.readOnly}
                                    //readOnly={this.state.readOnly}
                                    item={item}
                                    index={index}
                                    t={t}
                                    onChange={(event) => this.onChange(event)}
                                    onChangeAddress={this.onChangeAddress}
                                    add={this.addTime}
                                    remove={this.removeItem}
                                    length={this.state.favouriteAddresses.length}
                                    value={this.state.favouriteAddresses[index]}
                                    customerReducer={customerReducer}
                                    state={this.state}
                                />
                            ))}

                            <Grid item md={1} xs={1} implementation="css" smDown component={Hidden} />
                            <Grid item md={9}>
                                <ListRelatedCustomers
                                    {...this.props}
                                    open={this.state.openModalForm}
                                    openModal={this.openModal}
                                    closeModal={this.closeModal}
                                    saveRelatedCustomer={this.saveRelatedCustomer}
                                />
                                <ListAbsencesAndPenalties
                                    {...this.props}
                                />
                                <Button
                                    onClick={() => this.getPersonalInfo()}
                                    variant="contained"
                                    color='primary'
                                    className={useStyles.button}
                                    startIcon={<DownloadIcon />}
                                    disabled={this.props.customerReducer.isSubmittingGDPR}
                                >{t('profile.shared.personalData.downloadMyData')}
                                </Button>
                                <div style={{ marginBottom: 20 }} />
                                <Button
                                    onClick={() => this.setState({ dialog: true })}
                                    variant="contained"
                                    color='secondary'
                                    className={useStyles.button}
                                    startIcon={<DeleteIcon />}
                                >{t('profile.shared.personalData.deleteAccount')}
                                </Button>

                                <AlertDialog
                                    open={this.state.dialog}
                                    title={t('profile.shared.personalData.deleteAccountAlert')}
                                    onClickAccept={() => this.props.removeMyProfile()}
                                    onClickCancel={() => this.toggleDiaolog()}
                                />
                                {this.props.customerReducer.isSubmittingGDPR &&
                                    <Spinner loading={true} />
                                }
                            </Grid>
                            <Grid item md={2} xs={1} implementation="css" smDown component={Hidden} />
                        </Grid>
                    </React.Fragment>
                    : <Spinner loading={true} />}
            </React.Fragment>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (stringConstants.API_Key_Google),
})(ProfileCustomerView);