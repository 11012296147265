import { memo, useCallback } from "react";
import { PropTypes } from "prop-types";
import { GridCenter, stylesMultipleTrip } from "../../details";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useSelectTrips } from "../../hooks/selectTrips.hook";
import { tripActions } from "../../../../../redux/actions/trip_actions";
import { multipleTripsActions } from "../../../../../redux/actions/multipleTrips_action";
import { Button, Checkbox, Grid } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import Item from "./item";

const ListOfTripsPlannedComponentC = ({
    handleMouseOver,
    handleCancelTrip,
    handleRetryTrip,
    handleScheduleReturn,
    listOfTrips,
}) => {
    const classes = stylesMultipleTrip();

    const areInReturnProcess = useSelector(
        (state) => state.multipleTripReducer.areInReturnProcess
    );

    const disableCheck = useCallback(
        (trip) => {
            if (trip.tripResponse == null || trip.hasErrors) return true;
            return (
                moment(trip.tripResponse?.schedulePickUpTime).isBefore(
                    moment()
                ) ||
                areInReturnProcess ||
                trip.tripResponse?.isReturn
            );
        },
        [areInReturnProcess]
    );

    const {
        selectedTrips,
        handleChangeSelectTrip,
        canScheduleReturn,
        selectAll,
        selectedAll,
        tripToPreview,
        changeTripSelectedToPreview,
        canScheduleSomeTrip,
    } = useSelectTrips({ disableCheck });
    const dispatch = useDispatch();

    const handleClickTrip = useCallback(
        (e, trip) => {
            if (
                e.target.type !== "DIV" &&
                trip.tripResponse != null &&
                !areInReturnProcess
            )
                changeTripSelectedToPreview(trip.tripResponse);
        },
        [areInReturnProcess, changeTripSelectedToPreview]
    );

    const handleOnClickButtonSchedule = useCallback(() => {
        if (!areInReturnProcess) handleScheduleReturn();
        else {
            dispatch(
                tripActions.setTrip(
                    listOfTrips.find((item) => item.tripResponse).tripResponse
                )
            );
            dispatch(multipleTripsActions.updateScheduleReturn(false));
        }
    }, [areInReturnProcess, dispatch, handleScheduleReturn, listOfTrips]);

    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{ justifyContent: "center" }}
                spacing={2}
            >
                <h2>{i18next.t("form.multipleBook.list.title")}</h2>
                {canScheduleSomeTrip && (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!canScheduleReturn}
                        onClick={handleOnClickButtonSchedule}
                    >
                        {!areInReturnProcess
                            ? i18next.t(
                                  "form.resumeService.scheduleReturnTripButton"
                              )
                            : i18next.t("form.requestTripForm.buttonCancel")}
                    </Button>
                )}
            </Grid>
            <Grid item md={12} style={{ display: "flex", marginTop: 15 }}>
                <Grid item xl={3} md={2} className={`${classes.center}`}>
                    {canScheduleSomeTrip && (
                        <Checkbox
                            checked={selectedAll}
                            onChange={selectAll}
                            color="primary"
                        />
                    )}
                </Grid>
                <GridCenter>{i18next.t("form.multipleBook.date")}</GridCenter>
                <GridCenter>{i18next.t("form.multipleBook.status")}</GridCenter>
            </Grid>
            {listOfTrips.map((trip) => {
                return (
                    <Item
                        key={trip.tripId}
                        trip={trip}
                        handleClickTrip={handleClickTrip}
                        handleMouseOver={handleMouseOver}
                        handleRetryTrip={handleRetryTrip}
                        handleCancelTrip={handleCancelTrip}
                        selectedTrips={selectedTrips}
                        tripToPreview={tripToPreview}
                        handleChangeSelectTrip={handleChangeSelectTrip}
                        disableCheck={disableCheck}
                        listOfTrips={listOfTrips}
                    />
                );
            })}
        </>
    );
};

ListOfTripsPlannedComponentC.propTypes = {
    handleMouseOver: PropTypes.func.isRequired,
    handleCancelTrip: PropTypes.func.isRequired,
    handleRetryTrip: PropTypes.func.isRequired,
    handleScheduleReturn: PropTypes.func.isRequired,
    listOfTrips: PropTypes.array.isRequired,
};

export const ListOfTripsPlannedComponent = memo(
    ListOfTripsPlannedComponentC,
    (prevProps, nextProps) => prevProps.listOfTrips === nextProps.listOfTrips
);
