import Dashboard from '../dashboard'
import React from 'react';
import ProfileCustomerView from './page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { userActions } from '../../redux/actions/user_actions';
import { customerActions } from '../../redux/actions/customer_action';

class Profile extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Dashboard
                    component={
                        <ProfileCustomerView
                            {...this.props}
                        />
                    }
                />
            </React.Fragment>
        );
    }
}


function mapState(state) {
    const { customerReducer } = state;
    return { customerReducer };
}

const actionCreators = {
    getCustomerProfile: userActions.getCustomerProfile,
    updateCustomerProfile: customerActions.updateCustomerProfile,
    addRelatedCustomer: customerActions.addRelatedCustomer,
    editRelatedCustomer: customerActions.editRelatedCustomer,
    removeRelatedCustomer: customerActions.removeRelatedCustomer,
    typeOperation: customerActions.openModal,
    removeMyProfile: userActions.removeMyProfile,
    getPersonalInfo: userActions.getPersonalInfo,
    customerAddresses: customerActions.customerAddress
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Profile);