import React from 'react';
import MaterialTableExtended from '../../material-table'
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddBox";
import FormRelatedCustomers from './formRelatedCustomers';


class ListRelatedCustomers extends React.Component {

    constructor(props) {
        super()
        this.addOnClick = this.addOnClick.bind(this);
        this.editOnClick = this.editOnClick.bind(this);
    }

    addOnClick() {
        this.props.typeOperation('add');
        this.props.openModal();
    }

    editOnClick(item) {
        this.props.typeOperation('edit', item);
        this.props.openModal();
    }

    render() {
        const { t, customerReducer } = this.props
        const translations = t('material_table.tableStrings', { returnObjects: true })
        const Wrapper = () => (
            <React.Fragment>
                <Tooltip title={t('profile.shared.relatedCustomer.wrapper.add')}>
                    <IconButton onClick={this.addOnClick}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <MaterialTableExtended
                    title={t('profile.shared.relatedCustomer.title')}
                    localization={translations}
                    buttons={
                        <Wrapper />
                    }
                    pageSize={5}
                    actionsColumnIndex={7}
                    columns={[
                        { title: t('profile.shared.relatedCustomer.columns.name'), field: 'name' },
                        { title: t('profile.shared.relatedCustomer.columns.surname'), field: 'surname1' },
                        { title: t('profile.shared.relatedCustomer.columns.email'), field: 'email' },
                        { title: t('profile.shared.relatedCustomer.columns.document'), field: 'identityDocumentCode' },
                        { title: t('profile.shared.relatedCustomer.columns.phone'), field: 'phoneNumber' }
                    ]}
                    data={customerReducer.relatedCustomers || []}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: t('profile.shared.relatedCustomer.actions.edit'),
                            onClick: (event, rowData) => this.editOnClick(rowData)
                        },
                        {
                            icon: 'cancel',
                            tooltip: t('profile.shared.relatedCustomer.actions.remove'),
                            onClick: (event, rowData) => {
                                this.props.removeRelatedCustomer(rowData)
                            }
                        }
                    ]}
                />
                <FormRelatedCustomers
                    open={this.props.open}
                    closeModal={this.props.closeModal}
                    t={this.props.t}
                    saveRelatedCustomer={this.props.saveRelatedCustomer}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
}

export default ListRelatedCustomers;