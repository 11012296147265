import { Box, Tooltip } from "@material-ui/core";
import i18next from "i18next";
import React from "react";
import GroupIcon from "@material-ui/icons/Group";
import RepeatIcon from "@material-ui/icons/Repeat";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PropTypes from "prop-types";

export const TripsIconsInfo = ({ rowData }) => {
    return (
        <Box display={"flex"} justifyContent={"center"}>
            {rowData.isOnRelatedCustomerBehalf && (
                <Tooltip
                    title={
                        i18next.t("bookings.columns.relatedTrip") +
                        rowData.relatedCustomerName
                    }
                    arrow
                >
                    <GroupIcon
                        color="primary"
                        style={{
                            fontSize: 15,
                            marginRight: 2,
                        }}
                    />
                </Tooltip>
            )}
            {rowData.isReturn && (
                <Tooltip title={i18next.t("bookings.columns.isReturn")} arrow>
                    <RepeatIcon
                        style={{
                            fontSize: 15,
                            marginRight: 2,
                        }}
                    />
                </Tooltip>
            )}
            {rowData.isMultipleRequest && (
                <Tooltip
                    title={i18next.t("bookings.columns.isMultipleRequest")}
                    arrow
                >
                    <ScheduleIcon
                        style={{
                            fontSize: 15,
                            marginRight: 2,
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    );
};

TripsIconsInfo.propTypes = {
    rowData: PropTypes.shape({
        isMultipleRequest: PropTypes.bool,
        isReturn: PropTypes.bool,
        isOnRelatedCustomerBehalf: PropTypes.bool,
        relatedCustomerName: PropTypes.string,
    }),
};
