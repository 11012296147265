import { userConstants } from '../../constants/user_constants';
import { userService } from '../../services/userService';
import { alertActions } from './alert_actions';
import { userActions } from './user_actions';
import i18next from 'i18next';

export const customerActions = {
    updateCustomerProfile,
    addRelatedCustomer,
    editRelatedCustomer,
    removeRelatedCustomer,
    openModal,
    customerAddress
};

function updateCustomerProfile(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses) {
    return dispatch => {
        dispatch(request());
        userService.updateCustomerProfile(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses).then(
            data => {
                if (data.hasChangedEmailAddress == false) {
                    dispatch(alertActions.success(i18next.t('services.customers.updateSuccess')));
                    dispatch(success());
                    dispatch(userActions.getCustomerProfile());
                }
                if (data.hasChangedEmailAddress == true) {
                    dispatch(success());
                    dispatch(userActions.logout());
                    dispatch(alertActions.success(i18next.t('services.customers.updateSuccess')));
                    setTimeout(() => {
                        dispatch(alertActions.warning(i18next.t('services.customers.reloginRequired')));
                    }, 2000);
                }
            },
            error => {
                let detailError = i18next.t('services.customers.errorOccurs');
                let status = '';
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                    // console.log("detailError => ", detailError);
                    // console.log("status => ", status);
                    // console.log("error => ", error);
                } catch (e) {
                    detailError = i18next.t('services.customers.errorOccurs');
                }
                if (status == 500) {
                    detailError = i18next.t('services.customers.errorOccurs');
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError));
                }
                if (error === 401) {
                    dispatch(userActions.refreshToken());
                    setTimeout(() => {
                        dispatch(updateCustomerProfile(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses));
                    }, 1500);
                }
                if (status == 400) {
                    if (detailError == 'EmailAlreadyExists') {
                        detailError = i18next.t('services.customers.repeatEmail');
                    }
                    if (detailError == 'DocumentCodeAlreadyExists') {
                        detailError = i18next.t('services.customers.repeatDoc');
                    }
                    if (detailError == 'UserEmailAlreadyExists') {
                        detailError = i18next.t('services.customers.errorUserExists');
                    }
                    dispatch(failure(detailError));
                    dispatch(alertActions.error(detailError))
                }
                //console.log("detailError => ", detailError);
            }
        )
    }
    function request() {
        return { type: userConstants.SETUSER_REQUEST }
    }
    function success() {
        return { type: userConstants.SETUSER_SUCCESS }
    }
    function failure(error) {
        return { type: userConstants.SETUSER_FAILURE, error }
    }
}

function addRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.ADD_RELATEDCUSTOMER, customer }
    }
}

function editRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.EDIT_RELATEDCUSTOMER, customer }
    }
}

function removeRelatedCustomer(customer) {
    return dispatch => {
        dispatch(request(customer))
    }

    function request(customer) {
        return { type: userConstants.REMOVE_RELATEDCUSTOMER, customer }
    }
}

function openModal(typeOperation, user = null) {
    return dispatch => {
        dispatch(request(typeOperation))

    }
    function request(typeOperation) {
        return { type: userConstants.OPEN_MODAL, typeOperation, user }
    }
}

function customerAddress() {
    return dispatch => {
        dispatch(request());
        userService.customerAddress().then(
            results => {
                const typ = makeEnum(results);
                dispatch(success(typ));
            },
            error => {
                if (error === 401) {
                    dispatch(userActions.refreshToken())
                    dispatch(customerAddress())
                }
                dispatch(failure(error));

            }
        )
    }

    function request() {
        return { type: userConstants.CUSTOMER_ADDRESSES_REQUEST }
    }

    function success(results) {
        return { type: userConstants.CUSTOMER_ADDRESSES_SUCCESS, results }
    }

    function failure(error) {
        return { type: userConstants.CUSTOMER_ADDRESSES_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    let all = [];
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        });
    }
    return all;
}