import { FormControl, Grid, Hidden, TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { factory } from '../../../helpers/factory';
import utils from '../../../helpers/validations'
import moment from 'moment';
import InputAutocompleteProfile from '../../custom/autocompleteProfile';

const styles = makeStyles((theme) => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}));


class PersonalData extends React.Component {

    constructor(props) {
        super()
        const user = props.user ? props.user : props.customerReducer.edit ? props.customerReducer.userEdit : {}
        this.state = {
            id: user.id || null,
            name: user.name || '',
            surname1: user.surname1 || '',
            surname2: user.surname2 || '',
            email: user.email || '',
            identityDocumentCode: user.identityDocumentCode || '',
            phoneNumber: user.phoneNumber || '',
            birthDate: user.birthDate ? moment(user.birthDate).format('YYYY-MM-DD') || '' : '',
            isPMR: user.isPMR || false,
            hasWheelChair: user.hasWheelChair || false,
            addressPosition: user.addressPosition || '',
            errors: {
                name: { 'result': false, 'message': '' },
                surname1: { 'result': false, 'message': '' },
                surname2: { 'result': true, 'message': '' },
                birthDate: { 'result': false, 'message': '' },
                email: { 'result': false, 'message': '' },
                identityDocumentCode: { 'result': false, 'message': '' },
                phoneNumber: { 'result': false, 'message': '' },
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickSaveCustomer = this.onClickSaveCustomer.bind(this);
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value })
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result)
                valid = false
        });
        if (this.props.errorsFavouriteAddresses) {
            this.props.errorsFavouriteAddresses.forEach(element => {
                properties = Object.getOwnPropertyNames(element)
                properties.forEach(key => {
                    //console.log("element[key] => ", element[key])
                    if (!element[key].result)
                        valid = false
                });
            });
        }
        //console.log("isformvalid => ", valid)
        return valid
    }

    // validateForm() {
    //     let errors = this.state.errors
    //     errors.name = utils.validateName(this.state.name)
    //     errors.surname1 = utils.validateLastName(this.state.surname1)
    //     errors.surname2 =
    //         this.state.surname2 != ''
    //             ? utils.validateLastName(this.state.surname2)
    //             : { result: true, message: '' }
    //     let detail = this.props.detail
    //     if (!detail) {
    //         if (this.state.email != '' && !this.props.isModal) {
    //             errors.email = utils.validateEmail(this.state.email)
    //         } else {
    //             errors.email = { result: true, message: '' }
    //         }
    //         errors.birthDate = utils.required(this.state.birthDate)
    //         if (errors.birthDate.result && !this.props.isModal) {
    //             errors.birthDate = utils.calculateAge(this.state.birthDate, 18)
    //         }
    //         if (!this.props.isModal) {
    //             errors.phoneNumber = utils.required(this.state.phoneNumber)
    //             if (errors.phoneNumber.result) {
    //                 errors.phoneNumber = utils.validatePhone(
    //                     this.state.phoneNumber,
    //                 )
    //             }
    //         } else {
    //             errors.phoneNumber = { result: true, message: '' }
    //         }
    //         if (!this.props.isModal)
    //             errors.identityDocumentCode = utils.validateID(this.state.identityDocumentCode)
    //         else
    //             errors.identityDocumentCode = { 'result': true, 'message': '' }

    //         if (this.props.validateFavouriteAddresses)
    //             this.props.validateFavouriteAddresses()
    //     }

    //     this.setState({ errors })
    // }

    validateForm() {
        let errors = this.state.errors;
        errors.name = utils.validateName(this.state.name.trim())
        errors.surname1 = utils.validateLastName(this.state.surname1.trim())
        if (this.state.surname2 != "") {
            errors.surname2 = utils.validateLastName(this.state.surname2.trim())
        } else {
            errors.surname2 = { 'result': true, 'message': '' }
        }
        errors.birthDate = utils.required(this.state.birthDate)
        if (errors.birthDate.result && !this.props.isModal) {
            errors.birthDate = utils.calculateAge(this.state.birthDate, 18)
        }
        if (!this.props.isModal) {
            errors.email = utils.validateEmail(this.state.email.trim())
            errors.phoneNumber = utils.validatePhone(this.state.phoneNumber.trim())//utils.required(this.state.phoneNumber)
            errors.identityDocumentCode = utils.validateID(this.state.identityDocumentCode.trim())
        } else {
            if (this.state.email != "" && errors.email.result) {
                errors.email = utils.validateEmail(this.state.email.trim())
            } else {
                errors.email = { 'result': true, 'message': '' }
            }
            if (this.state.phoneNumber != "" && errors.phoneNumber.result) {
                errors.phoneNumber = utils.validatePhone(this.state.phoneNumber.trim())
            } else {
                errors.phoneNumber = { 'result': true, 'message': '' }
            }
            if (this.state.identityDocumentCode != "" && errors.identityDocumentCode.result) {
                errors.identityDocumentCode = utils.validateID(this.state.identityDocumentCode.trim())
            } else {
                errors.identityDocumentCode = { 'result': true, 'message': '' }
            }
        }
        if (this.props.validateFavouriteAddresses) {
            this.props.validateFavouriteAddresses()
        }
        this.setState({ errors })
    }

    onClick() {
        this.validateForm()
        if (this.isFormValid()) {
            const customer = factory.createCustomer(this.state.name.trim(), this.state.surname1.trim(), this.state.surname2.trim(), this.state.identityDocumentCode.trim(), this.state.email.trim(),
                this.state.phoneNumber.trim(), this.state.isPMR, this.state.hasWheelChair, this.state.birthDate)
            if (this.props.customerReducer.add) {
                this.props.addRelatedCustomer(customer)
            } else {
                customer.tableData = { id: this.props.customerReducer.userEdit.tableData.id }
                let editCustomer = { ...customer, id: this.state.id }
                console.log("editCustomer => ", editCustomer)
                //customer = { ...customer, id: this.props.customerReducer.userEdit.id }
                this.props.editRelatedCustomer(editCustomer)
            }
            this.props.closeModal()
        }
    }

    onClickSaveCustomer() {
        this.validateForm()
        if (this.isFormValid()) {
            let related = [];
            related = this.props.customerReducer.relatedCustomers;
            this.props.updateCustomerProfile(this.state.email.trim(), this.state.name.trim(), this.state.surname1.trim(), this.state.surname2.trim(), this.state.phoneNumber.trim(), this.state.identityDocumentCode.trim(), this.state.isPMR, this.state.hasWheelChair, related, this.state.birthDate, this.state.addressPosition, this.props.favouriteAddresses);
        }
    }

    getISOStringWithoutSecsAndMillisecs1 = () => {
        const dateAndTime = new Date().toISOString().split('T')
        const time = dateAndTime[1].split(':')

        return dateAndTime[0] + 'T' + time[0] + ':' + time[1]
    }

    getMaxISOStringWithoutSecsAndMillisecs1 = () => {
        let dateAndTime = new Date()
        dateAndTime.setFullYear(9999);
        dateAndTime = dateAndTime.toISOString().split('T')
        const time = dateAndTime[1].split(':')

        return dateAndTime[0] + 'T' + time[0] + ':' + time[1]
    }

    async geoCodeAddress(address) {
        if (address != null) {
            this.setState({
                addressPosition: {
                    'address': address.description,
                    'lat': 0,
                    'lon': 0
                }
            })
            // findLatLang(address.address)
            //     .then(
            //         locationLatLng => {
            //             console.log("location => ", locationLatLng);
            //             const lat = locationLatLng != null ? locationLatLng[0] : null;//coords.lat;
            //             const lng = locationLatLng != null ? locationLatLng[1] : null;//coords.lng;
            //             this.setState({
            //                 addressPosition: {
            //                     'address': address.description,
            //                     'lat': lat,
            //                     'lon': lng
            //                 }
            //             });
            //         },
            //         error => {
            //             console.log("error => ", error);
            //             this.setState({
            //                 addressPosition: null
            //             })
            //         }
            //     )
            //     .catch(error => {
            //         console.log("error => ", error);
            //         this.setState({
            //             addressPosition: {
            //                 'address': address.description,
            //                 'lat': 0,
            //                 'lon': 0
            //             }
            //         }, ()=>console.log("this.state.addressPosition => ", this.state.addressPosition));
            //     })
        } else {
            this.setState({
                addressPosition: null
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Grid className={styles.root} container spacing={3} style={{ paddingTop: 30, marginBottom: 50 }}>
                {/* <h2>{this.props.title}</h2> */}
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.name')}
                            value={this.state.name || ''}
                            required
                            variant="outlined"
                            name="name"
                            fullWidth
                            onChange={this.handleChange}
                            error={this.state.errors.name.message.length === 0 ? false : true}
                            helperText={this.state.errors.name.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.surname1')}
                            value={this.state.surname1 || ''}
                            required
                            variant="outlined"
                            name="surname1"
                            fullWidth
                            onChange={this.handleChange}
                            error={this.state.errors.surname1.message.length === 0 ? false : true}
                            helperText={this.state.errors.surname1.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.surname2')}
                            value={this.state.surname2 || ''}
                            variant="outlined"
                            name="surname2"
                            fullWidth
                            onChange={this.handleChange}
                            error={this.state.errors.surname2.message.length === 0 ? false : true}
                            helperText={this.state.errors.surname2.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} implementation="css" smDown component={Hidden} />


                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.document')}
                            value={this.state.identityDocumentCode || ''}
                            required={this.props.isModal ? false : true}
                            variant="outlined"
                            name="identityDocumentCode"
                            //disabled={this.props.isRelated ? false : true}
                            fullWidth
                            onChange={this.handleChange}
                            error={this.state.errors.identityDocumentCode.message.length === 0 ? false : true}
                            helperText={this.state.errors.identityDocumentCode.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.email')}
                            value={this.state.email || ''}
                            required={this.props.isModal ? false : true}
                            //disabled={this.props.isRelated ? false : true}
                            variant="outlined"
                            name="email"
                            fullWidth
                            onChange={this.handleChange}
                            type={'email'}
                            error={this.state.errors.email.message.length === 0 ? false : true}
                            helperText={this.state.errors.email.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            label={t('profile.shared.personalData.phone')}
                            value={this.state.phoneNumber || ''}
                            required={this.props.isModal ? false : true}
                            variant="outlined"
                            name="phoneNumber"
                            fullWidth
                            type={'tel'}
                            onChange={this.handleChange}
                            error={this.state.errors.phoneNumber.message.length === 0 ? false : true}
                            helperText={this.state.errors.phoneNumber.message}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} implementation="css" smDown component={Hidden} />

                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <TextField
                            //label={t('customers.form.page.personalData.birthDate')}
                            value={this.state.birthDate || ''}
                            required
                            variant="outlined"
                            name="birthDate"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={this.handleChange}
                            type="date"
                            error={this.state.errors.birthDate.message.length === 0 ? false : true}
                            helperText={this.state.errors.birthDate.message}
                            inputProps={
                                { readOnly: false, min: this.getISOStringWithoutSecsAndMillisecs1(), max: this.getMaxISOStringWithoutSecsAndMillisecs1() }
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item md={3} xs={6} sm={3} >
                    <FormControl fullWidth>
                        <InputAutocompleteProfile
                            id='addressPosition'
                            label={t('profile.shared.personalData.address')}
                            editable={true}
                            defaultValue={this.props.customerReducer.currentUser?.addressPosition?.address}
                            setAddress={(addressObject) => {
                                this.geoCodeAddress(addressObject)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} implementation="css" smDown component={Hidden} />

                <Grid item md={3} implementation="css" smDown component={Hidden} />
                <Grid item md={2} xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.isPMR}
                                required
                                onChange={() => this.setState({ isPMR: !this.state.isPMR })}
                                color="primary"
                            />
                        }
                        label={t('profile.shared.personalData.isPmr')}
                    />
                </Grid>
                <Grid item md={2} xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.hasWheelChair}
                                required
                                onChange={() => this.setState({ hasWheelChair: !this.state.hasWheelChair })}
                                color="primary"
                            />
                        }
                        label={t('profile.shared.personalData.hasWheelChair')}
                    />
                </Grid>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                {
                    this.props.withAction ?
                        <Grid item md={2} xs={6} sm={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                onClick={this.props.saveCustomer ? this.onClickSaveCustomer : this.onClick}
                                //onClick={this.onClick}
                                size="large">
                                {t('profile.shared.personalData.save')}
                            </Button>
                        </Grid>
                        : null
                }

            </Grid>
        );
    }
}

export default PersonalData;