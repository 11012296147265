import { userConstants } from '../../constants/user_constants';
import { userService } from '../../services/userService';
import { alertActions } from '../actions/alert_actions';
import { history } from '../../helpers/history';
import crypto from '../../helpers/crypto';
import stringConstants from '../../constants/strings';
import i18next from 'i18next';
import store from '../store';
import * as Time from '../../constants/time';

export const userActions = {
    login,
    getTokenId,
    register,
    logout,
    forgotPassword,
    getCustomerProfile,
    refreshToken,
    removeMyProfile,
    getPersonalInfo
};

function login(loginId, password, remember) {
    return dispatch => {
        dispatch(request());
        if (remember) {
            let infoLogin = { loginId, password, remember };
            localStorage.setItem("remember", crypto.encryptObject(infoLogin));
        }
        userService.isActive(loginId).then(
            resultActive => {
                //console.log("resultActive => ", resultActive);
                if (resultActive) {
                    userService.login(loginId, password).then(
                        data => {
                            if (data.user.emailVerified) {
                                dispatch(success(data));
                                userService.setWithExpiry('token_customer', data.token, Time.TOKEN_EXPIRATION);
                                userService.setWithExpiry('refreshToken_customer', data.user.refreshToken, Time.INFINITE);
                                userService.setWithExpiry('user_customer', data.user, Time.TOKEN_EXPIRATION);
                                dispatch(getTokenId());
                            } else {
                                dispatch(alertActions.error(i18next.t('services.customers.errorEmailVerifiedPending')))
                                //data.user.sendEmailVerification()
                                dispatch(failure("emailNotVerified", data.user));
                            }
                        },
                        error => {
                            if (error.code == "auth/wrong-password") {
                                dispatch(alertActions.error(i18next.t('services.customers.invalidCredentials')));
                            } else {
                                dispatch(alertActions.error(i18next.t('services.customers.errorRepeatAgain')));
                            }
                            dispatch(failure(error));
                        }
                    )
                } else {
                    dispatch(alertActions.error(i18next.t('services.customers.isNotActive')));
                    dispatch(failure("isNotActive"));
                }
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.customers.invalidCredentials')))
                dispatch(failure(error))
            }
        )

    }

    function request() {
        return { type: userConstants.LOGIN_REQUEST }
    }
    function success(data) {
        return { type: userConstants.LOGIN_SUCCESS, data }
    }
    function failure(error, data) {
        return { type: userConstants.LOGIN_FAILURE, error, data }
    }
}

function getCustomerProfile() {
    return dispatch => {
        dispatch(request())
        userService.getCustomerProfile().then(
            data => {
                //console.log("data getCustomerProfile => ", data);
                dispatch(success(data))
                if (store.getState().customerReducer.currentUser != null) {
                    if (data.language != store.getState().customerReducer.currentUser.language) {
                        userService.setLanguage(store.getState().customerReducer.currentUser.language);
                    }
                }
                i18next.changeLanguage(data.language);
            },
            error => {
                if (error == "401") {
                    dispatch(refreshToken())
                    dispatch(getCustomerProfile())
                }
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: userConstants.GETBYID_REQUEST }
    }
    function success(data) {
        return { type: userConstants.GETBYID_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.GETBYID_FAILURE, error }
    }
}

function getTokenId() {
    return dispatch => {
        dispatch(request())
        userService.getTokenId().then(
            data => {
                //console.log("data getTokenId => ", data);
                if (data.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == stringConstants.CUSTOMER_USER) {
                    //localStorage.setItem("customerId", data.claims.mongo_id)
                    userService.setWithExpiry("customerId", data.claims.mongo_id, Time.TOKEN_EXPIRATION)
                    //localStorage.setItem('userRole', data.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
                    userService.setWithExpiry('userRole', data.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'], Time.TOKEN_EXPIRATION)
                    dispatch(success(data))
                    history.push('/home');
                    userService.setLanguage(i18next.language)
                } else {
                    dispatch(logout());
                    history.push('/login');
                    let role = data.claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                    dispatch(failure('Usuari sense permís per accedir a aquesta APP (role=' + role + ')'));
                    dispatch(alertActions.error(i18next.t('services.customers.invalidCredentials')));
                }
            },
            error => {
                dispatch(failure(error))
            }
        )

    }

    function request() {
        return { type: userConstants.TOKEN_REQUEST }
    }
    function success(data) {
        return { type: userConstants.TOKEN_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.TOKEN_FAILURE, error }
    }
}

function register(email, password, firstName, lastName, document, gdprAccepted, birthDate, phoneNumber) {
    return dispatch => {
        dispatch(request())
        userService.registerCustomer(email, password, firstName, lastName, document, gdprAccepted, birthDate, phoneNumber).then(
            user => {
                userService.sendVerifyEmail(email, i18next.language);
                dispatch(success(user));
                dispatch(alertActions.success(i18next.t('services.customers.registerSuccess')));
                history.push('/login');
            },
            error => {
                let detailError = i18next.t('services.customers.registerError')//"Ha ocurrido un error al realizar el registro";
                let status = '';
                try {
                    let parsedError = JSON.parse(error);
                    detailError = parsedError.detail;
                    status = parsedError.status;
                    if (detailError == 'EmailAlreadyExists') {
                        dispatch(alertActions.error(i18next.t('services.customers.repeatEmail')));
                    } else if (detailError == 'UserEmailAlreadyExists') {
                        dispatch(alertActions.error(i18next.t('services.customers.errorUserExists')));
                    } else if (detailError == 'DocumentCodeAlreadyExists') {
                        dispatch(alertActions.error(i18next.t('services.customers.repeatDoc')));
                    } else if (detailError == "InvalidDocument") {
                        //message pending ?
                        dispatch(alertActions.error(i18next.t('services.customers.registerError')));
                    } else {
                        dispatch(alertActions.error(i18next.t('services.customers.registerError')));
                    }
                    dispatch(failure(error));
                } catch (e) {
                    dispatch(alertActions.error(i18next.t('services.customers.registerError')));
                }
            }
        )
    }

    function request() {
        return { type: userConstants.REGISTER_REQUEST }
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user }
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error }
    }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request())
        userService.forgotPassword(email)
            .then(
                data => {
                    if (data == 'error') {
                        dispatch(failure('El email introducido no corresponde a ningún customer'));
                        dispatch(alertActions.success(i18next.t('services.customers.checkYourEmailToRestore')));
                    } else {
                        dispatch(success(data))
                        dispatch(alertActions.success(i18next.t('services.customers.checkYourEmailToRestore')));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.success(i18next.t('services.customers.checkYourEmailToRestore')));
                    //dispatch(alertActions.error("Ha ocurrido un error y no se puede restaurar password"))
                }
            )
    }

    function request() {
        return { type: userConstants.RESETPSW_REQUEST }
    }
    function success(user) {
        return { type: userConstants.RESETPSW_SUCCESS, user }
    }
    function failure(error) {
        return { type: userConstants.RESETPSW_FAILURE, error }
    }
}

function refreshToken() {
    return dispatch => {
        dispatch(request())
        userService.refreshToken().then(
            data => {
                dispatch(success(data))
            },
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: userConstants.REFRESH_REQUEST }
    }
    function success(token) {
        return { type: userConstants.REFRESH_SUCCESS, token }
    }
    function failure(error) {
        return { type: userConstants.REFRESH_FAILURE, error }
    }


}

function logout() {
    return dispatch => {
        dispatch(request())
        userService.logout().then(
            result => {
                // localStorage.removeItem('token_customer')
                // localStorage.removeItem('customerId')
                // localStorage.removeItem('remember')
                // localStorage.removeItem('refreshToken_customer')
                // localStorage.removeItem('user_customer')
                // localStorage.removeItem('userRole')
                localStorage.clear();
                history.push("/login")
            }
        )
    }

    function request() {
        return { type: userConstants.LOGOUT }
    }
}

function removeMyProfile() {
    return dispatch => {
        dispatch(request());
        userService.removeMyProfile().then(
            data => {
                if (data == 200) {
                    dispatch(success());
                    dispatch(logout());
                    //Alert.alert(Generics.INFO, 'Se ha dado de baja correctamente');
                } else {
                    if (data == 401) {
                        dispatch(refreshToken());
                        setTimeout(() => {
                            dispatch(removeMyProfile());
                        }, 1500);
                    } else {
                        dispatch(alertActions.error(i18next.t('services.trips.errorOccurs')));
                        dispatch(failure('status ' + data));
                    }
                }
            },
        );
    }
    function request() {
        return { type: userConstants.REMOVE_CUSTOMER_REQUEST }
    }
    function success() {
        return { type: userConstants.REMOVE_CUSTOMER_SUCCESS }
    }
    function failure(error) {
        return { type: userConstants.REMOVE_CUSTOMER_FAILURE, error }
    }
}

function getPersonalInfo() {
    return dispatch => {
        dispatch(request())
        userService.getPersonalInfo().then(
            data => {
                //console.log("data getPersonalInfo => ", data);
                dispatch(success(data));
                dispatch(alertActions.success(i18next.t("profile.shared.personalData.GDPRPetitionMessageOk")))
            },
            error => {
                let errorParsed = i18next.t('services.trips.errorOccurs');
                try {
                    errorParsed = JSON.parse(error);
                    // console.log("error getPersonalInfo => ", error);
                    // console.log("errorParsed => ", errorParsed);
                    if (errorParsed.detail.includes("GDPRRequestOutOfHours") && errorParsed.status == 400) {
                        dispatch(alertActions.warning(i18next.t("profile.shared.personalData.GDPRPetitionMessageOutOfHours")))
                    } else {
                        dispatch(alertActions.error(i18next.t('services.trips.errorOccurs')));
                    }
                } catch (e) {
                    dispatch(alertActions.error(i18next.t('services.trips.errorOccurs')));
                }
                if (error == "401") {
                    dispatch(refreshToken())
                    setTimeout(() => {
                        dispatch(getPersonalInfo())
                    }, 1500);
                }
                dispatch(failure(errorParsed));
            }
        )
    }

    function request() {
        return { type: userConstants.GET_PERSONAL_INFO_REQUEST }
    }
    function success(data) {
        return { type: userConstants.GET_PERSONAL_INFO_SUCCESS, data }
    }
    function failure(error) {
        return { type: userConstants.GET_PERSONAL_INFO_FAILURE, error }
    }
}