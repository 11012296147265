export const STATES = {
    'All': {
        'id': -1,
        'name': 'All'
    },
    'Open': {
        'id': 10,
        'name': 'Open'
    },
    'Pending': {
        'id': 20,
        'name': 'Pending'
    },
    'NotAvailable': {
        'id': 30,
        'name': 'NotAvailable'
    },
    'Available': {
        'id': 40,
        'name': 'Available'
    },
    'Accepted': { //byUser
        'id': 50,
        'name': 'Accepted'
    },
    'AcceptedByCustomerService': {
        'id': 51,
        'name': 'Accepted by OAC'
    },
    'AcceptedByDriver': {
        'id': 52,
        'name': 'AcceptedByDriver'
    },
    'AcceptedByIVR': {
        'id': 53,
        'name': "AcceptedByIVR"
    },
    'Rejected': { //byUser
        'id': 60,
        'name': 'RejectedByUser'
    },
    'RejectedByCustomerService': {
        'id': 62,
        'name': 'RejectedByCustomerService'
    },
    'RejectedBySystem': {
        'id': 63,
        'name': 'RejectedBySystem'
    },
    'RejectedByAllDrivers': {
        'id': 64,
        'name': 'RejectedByAllDrivers'
    },
    'NoDriversAvailable': {
        'id': 66,
        'name': 'NoDriversAvailable'
    },
    'CancelledFilter': {
        'name': 'Cancelled'
    },
    'Cancelled': { //byUser
        'id': 70,
        'name': 'CancelledByUser'
    },
    'CancelledByCustomerService': {
        'id': 71,
        'name': 'Cancelled by customer service'
    },
    'CancelledForModification': {
        'id': 72,
        'name': 'Cancelled for modification'
    },
    'CancelledByCustomerServiceWheel': {
        'id': 73,
        'name': 'Cancelled by customer service wheel'
    },
    'Ongoing': {
        'id': 80,
        'name': 'Ongoing'
    },
    'NotAttended': {
        'id': 90,
        'name': 'NonAttendance'
    },
    'NotPerformed': {
        'id': 91,
        'name': 'NotPerformed'
    },
    'Finished': {
        'id': 100,
        'name': 'Finished'
    },
    'FinishedBySystem': {
        'id': 101,
        'name': 'FinishedBySystem'
    },
    "DriverAssignedByOperator": {
        'id': 110,
        'name': "DriverAssignedByOperator"
    },
    'DriverAssignedAfterCompaction': {
        'id': 111,
        'name': "DriverAssignedAfterCompaction"
    },

}